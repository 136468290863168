import { fetchProducts, updateProduct } from '@/api/product'
import { withOptions } from '@/assets/js/utils'
import { mdiAccountCheckOutline, mdiAccountOutline, mdiAccountPlusOutline, mdiAccountRemoveOutline } from '@mdi/js'
import { ref, watch } from '@vue/composition-api'

const cached = []

export default function useProductsList() {
  const productListTable = ref([])

  const tableColumns = [
    { text: 'IMAGE', value: 'media', sortable: false },
    { text: 'ID', value: 'product_number' },
    { text: 'PRODUCT', value: 'product', sortable: false },
    { text: 'PRICE', value: 'max_sku_price' },
    { text: 'CATEGORY', value: 'category', sortable: false },
    { text: 'STATUS', value: 'status' },
    { text: 'TAGS', value: 'tag', sortable: false },
    { text: 'UPDATED AT', value: 'updated_at' },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
    },
  )
  const searchQuery = ref(cached[1] || '')
  const natureFilter = ref(cached[2] || null)
  const categoryFilter = ref(cached[3] || null)
  const statusFilter = ref(cached[4] || null)
  const totalListTable = ref(0)
  const loading = ref(false)
  const tagFilter = ref(cached[5] || null)
  const totalLocal = ref([])
  const selectedRows = ref([])

  // must follow cached[0...4] variable order above.

  // fetch data
  const loadProducts = () =>
    fetchProducts(
      withOptions(options.value, {
        search: searchQuery.value,
        status: statusFilter.value,
        nature: natureFilter.value,
        category_ids: categoryFilter.value ? [categoryFilter.value] : undefined,
        tag_ids: tagFilter.value ? [tagFilter.value] : undefined,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data

        productListTable.value = records
        totalListTable.value = pagination.total
        totalLocal.value = null

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const updateAllProducts = async updates => {
    loading.value = true
    for (const product of selectedRows.value) {
      const data = { ...updates }
      await updateProduct(product.id, data)
    }
    loadProducts()
    loading.value = false
    selectedRows.value = []
  }
  const cachable = [options, searchQuery, natureFilter, categoryFilter, statusFilter, tagFilter]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  cache()

  watch(cachable, () => {
    const newPage = options.value.page

    // reset page if filter others (i.e. no page change)
    if (newPage >= 2 && newPage === cached[0].page) {
      options.value.page = 1
    }
    loading.value = true
    selectedRows.value = []
    loadProducts()
    cache()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'draft') return 'warning'
    if (status === 'published') return 'success'
    if (status === 'unpublished') return 'danger'

    return 'primary'
  }

  const resolveUserTotalIcon = total => {
    if (total === 'Total Users') return { icon: mdiAccountOutline, color: 'primary' }
    if (total === 'Paid Users') return { icon: mdiAccountPlusOutline, color: 'error' }
    if (total === 'Active Users') return { icon: mdiAccountCheckOutline, color: 'success' }
    if (total === 'Pending Users') return { icon: mdiAccountRemoveOutline, color: 'warning' }

    return { icon: mdiAccountOutline, color: 'primary' }
  }

  return {
    productListTable,
    tableColumns,
    searchQuery,
    statusFilter,
    natureFilter,
    categoryFilter,
    tagFilter,
    totalListTable,
    loading,
    options,
    totalLocal,
    selectedRows,
    loadProducts,
    updateAllProducts,
    resolveStatusVariant,
    resolveUserTotalIcon,
  }
}

<template>
  <div id="user-list">
    <!-- user total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in totalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>

            <v-avatar
              :color="resolveUserTotalIcon(total.title).color"
              :class="`v-avatar-light-bg ${resolveUserTotalIcon(total.title).color}--text`"
            >
              <v-icon
                size="25"
                :color="resolveUserTotalIcon(total.title).color"
                class="rounded-0"
              >
                {{ resolveUserTotalIcon(total.title).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>
      <v-row class="px-2 ma-0">
        <!-- search filter -->
        <v-col
          cols="12"
          sm="3"
        >
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            hide-details
            dense
            class="user-search me-3 mb-4"
          >
          </v-text-field>
        </v-col>

        <!-- status filter -->
        <v-col
          cols="12"
          sm="6"
          class="d-flex"
        >
          <v-select
            v-model="statusFilter"
            placeholder="Status"
            :items="statusOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
          <v-select
            v-model="natureFilter"
            placeholder="Nature"
            :items="natureOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
          <v-select
            v-model="categoryFilter"
            placeholder="Category"
            :items="categoriesOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
          <v-select
            v-model="tagFilter"
            placeholder="Tag"
            :items="tagsOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="3"
        >
          <div class="d-flex align-center flex-wrap">
            <!-- <v-btn
              color="primary"
              class="mb-4 me-3"
              @click="$router.push({
                name: 'product-create-resource'
              })"
            >
              <v-icon>{{ icons.mdiPlus }}</v-icon>
              <span>Add New Product</span>
            </v-btn> -->
          </div>
        </v-col>
      </v-row>

      <div class="d-flex justify-end align-center flex-wrap">
        <template
          v-if="selectedRows.length"
        >
          <p class="mr-2">
            Bulk Actions:
          </p>
          <v-btn
            color="success"
            class="mb-4 me-3"
            :loading="loading"
            @click.stop="updateAllProducts({ active: 1 })"
          >
            <span>Activate</span>
          </v-btn>
          <v-btn
            color="warning"
            class="mb-4 me-3"
            @click.stop="updateAllProducts({ active: 0 })"
          >
            <span>Deactive</span>
          </v-btn>
        </template>
      </div>

      <v-divider class="mt-4"></v-divider>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="productListTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
        show-select
      >
        <!-- Image -->
        <template #[`item.media`]="{item}">
          <v-img
            v-if="item.media.length"
            :width="60"
            :src="item.media[0].instance.thumbnail"
          ></v-img>
        </template>

        <!-- ID -->
        <template #[`item.product_number`]="{item}">
          <div
            style="min-width: 120px;"
          >
            {{ item.product_number }} <span v-if="item.merchant_product_id">({{ item.merchant_product_id }}) </span>
          </div>
        </template>

        <!-- Name -->
        <template #[`item.product`]="{item}">
          <div class="d-flex align-center">
            <router-link
              :to="{ name: 'product-view', params: { id : item.id } }"
              class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              {{ t(item.title) }}
            </router-link>
          </div>
        </template>

        <!-- Price -->
        <template #[`item.max_sku_price`]="{item}">
          <div
            class="d-flex align-center"
            style="min-width: 200px;"
          >
            {{ item.reference_price.min_sku_price | price }} - {{ item.reference_price.max_sku_price | price }}
          </div>
        </template>

        <!-- Category -->
        <template #[`item.category`]="{item}">
          <!-- <p>{{ rootCategoryName(item.categories) }}</p> -->
          <v-chip
            v-for="cat in item.categories"
            :key="cat.id"
          >
            {{ t(cat.name) }}
          </v-chip>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveStatusVariant(item.status)"
            :class="`${resolveStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status }}
          </v-chip>
          <v-chip
            v-if="item.status !== 'unpublished'"
            small
            :color="item.active ? 'success' : ''"
            :class="`${item.active && item.status === 'published' ? 'success' : ''}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.active ? 'Active' : 'Inactive' }}
          </v-chip>
        </template>

        <!-- tag -->
        <template #[`item.tag`]="{item}">
          <v-chip
            v-for="tag in item.tags"
            :key="tag.id"
          >
            {{ t(tag.name) }}
          </v-chip>
        </template>

        <!-- updated at -->
        <template #[`item.updated_at`]="{item}">
          <div
            style="min-width: 100px;"
          >
            <span>{{ item.updated_at | date }}</span>
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'product-view',params:{id:item.id}}"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <!-- <v-list-item
                @click="replicateProduct(item)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiContentCopy }}
                  </v-icon>
                  <span>Replicate</span>
                </v-list-item-title>
              </v-list-item> -->

              <!-- <v-list-item
                v-if="item.status === 'draft'"
                link
                @click="confirmTrashProduct(item).then(loadProducts)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Trash</span>
                </v-list-item-title>
              </v-list-item> -->

              <!-- <v-list-item
                v-if="item.status === 'published'"
                link
                @click="unpublishProduct(item.id).then(loadProducts)"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                  </v-icon>
                  <span>Unpublish</span>
                </v-list-item-title>
              </v-list-item> -->
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiFileDocumentOutline,
  mdiContentCopy,
  mdiDeleteOutline,
  mdiExportVariant,
  mdiAccountOutline,
} from '@mdi/js'

// sidebar
import { avatarText, date, price } from '@core/utils/filter'
import appStore from '@/store/common'
import store from '@/store/product'

import { t } from '@/plugins/i18n'
import { notifySuccess } from '@/assets/js/utils'
import useProductList from './useProductList'
import useProduct from '../useProduct'

export default {
  components: {},
  filters: { date, price },
  setup() {
    const {
      productListTable,
      tableColumns,
      searchQuery,
      statusFilter,
      natureFilter,
      categoryFilter,
      tagFilter,
      totalListTable,
      loading,
      options,
      totalLocal,
      selectedRows,

      loadProducts,
      updateAllProducts,
      resolveStatusVariant,
      resolveUserTotalIcon,
    } = useProductList()

    const { unpublishProduct, confirmTrashProduct, confirmReplicate } = useProduct()

    const statusOptions = [
      { title: 'Draft', value: 'draft' },
      { title: 'Published', value: 'published' },
      { title: 'Unpublished', value: 'unpublished' },
    ]

    const rootCategoryName = cats => {
      const cat = cats.find(c => !c.parent_id)

      return cat ? t(cat.name) : ''
    }

    const categoriesOptions = appStore.state.categories.map(cat => ({ title: t(cat.name), value: cat.id }))
    const tagsOptions = appStore.state.tags.filter(tag => tag.type === 'product_status').map(tag => ({ title: t(tag.name), value: tag.id }))

    const replicateProduct = async product => {
      await confirmReplicate(product)
      loadProducts()
      notifySuccess({ content: 'Replication succeeded' })
    }

    return {
      productListTable,
      tableColumns,
      searchQuery,
      statusFilter,
      natureFilter,
      categoryFilter,
      tagFilter,
      totalListTable,
      statusOptions,
      natureOptions: store.state.natureOptions,
      categoriesOptions,
      tagsOptions,
      loading,
      options,
      totalLocal,
      selectedRows,
      avatarText,
      resolveStatusVariant,
      resolveUserTotalIcon,
      loadProducts,
      updateAllProducts,
      unpublishProduct,
      confirmTrashProduct,
      rootCategoryName,

      t,
      replicateProduct,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiContentCopy,
        mdiDotsVertical,
        mdiDeleteOutline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
